<template>
  <v-row>
    <v-col cols="12" class="date-filter-component">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :close-on-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            prepend-inner-icon="mdi-calendar"
            :value="formatedDate.toUpperCase()"
            clearable
            :label="$t('date')"
            readonly
            v-bind="attrs"
            v-on="on"
            color="#00a4b3"
            class="date"
            @click:clear="date = null"
            dense
            solo
          ></v-text-field>
        </template>
        <c-datepicker
          :minDate="minDate"
          :value="dateVal"
          :enableDays="[1, 2, 3, 4, 5, 6]"
          @changeValue="dateVal = $event"
          @change="menu = false"
        ></c-datepicker>
      </v-menu>
    </v-col>
    <v-spacer></v-spacer>
  </v-row>
</template>

<script>
import moment from "moment";
import CDatepicker from "@/components/v3/date/custom-datepicker.vue";

export default {
  components: {
    CDatepicker,
  },
  props: ["value"],
  data: () => ({
    menu: false,
  }),

  computed: {
    dateVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("change", val);
      },
    },

    formatedDate() {
      return this.dateVal
        ? moment(this.dateVal)
            .locale(this.$i18n.locale)
            .format("DD-MMM-YYYY")
            .toUpperCase()
        : "";
    },
    minDate() {
      return moment().format("YYYY-MM-DD");
    },
  },
  created() {
    this.date = this.minDate;
  },
};
</script>

<style lang="scss" scoped>
.date {
  color: rgba(0, 0, 0, 0.6);
  text-transform: capitalize;
}
</style>
