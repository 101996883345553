<template>
  <v-card
    class="card-branch"
  >
    <img
      class="card-branch__image"
      :src="
        item.image_url == null || item.image_url.length == 0
          ? require('@/assets/branch_image3.jpg')
          : `${url_his}${item.image_url[0]}`
      "
    />
    <v-row class="pa-3 flex-grow-1">
      <v-col
        cols="8"
      >
        <h5 class="mb-1 text-uppercase">{{ item.name }}</h5>
        <h6 class="mb-2 text-uppercase">{{ item.address }}</h6>
        <div class="desc">
          <c-button class="px-2 py-1 button" rounded>
            {{
              item.distance == undefined
                ? "-"
                : item.distance.toString() != 1000000000000
                ? "± " + Math.ceil(item.distance.toString() / 1000) + " KM"
                : "-"
            }}
          </c-button>
          <span>
            {{ $t("page.nearme.from_destination") }}
          </span>
        </div>
      </v-col>
      <v-col
        cols="4"
        class="d-flex flex-column justify-space-between"
      >
        <div
          class="d-flex flex-row justify-space-between align-end mb-3 flex-md-column"
        >
          <a
            :href="`https://www.google.com/maps/?q=${item.longlat}`"
            target="_blank"
            class="direction d-flex align-end justify-md-end"
          >
            <v-icon color="#1f3c87"> mdi-map-marker </v-icon>
            {{ $t("direction") }}</a
          >
          <p
            class="queue d-flex align-end justify-end mb-0"
            v-if="item.open_queue > 0"
          >
            <b class="count mr-1">{{ item.open_queue }}</b>
            {{ $t("page.nearme.queue_remaining") }}
          </p>
          <p class="queue d-flex align-end justify-md-end mb-0" v-else>
            {{ $t("page.nearme.no_queue") }}
          </p>
        </div>
        <div>
          <localized-link
            :to="`/patient/test?branch=${
              item.id
            }&date${date}=&category=${category.toString()}`"
          >
            <c-button rounded class="py-2 button" fluid>{{
              $t("menu.book_lab_test")
            }}</c-button>
          </localized-link>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import CButton from "@/components/v2/button/index.vue";
import { format, parseISO } from "date-fns";

export default {
  components: {
    CButton,
  },
  props: {
    category: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    url_his: process.env.VUE_APP_URL_HIS,
    date: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
  }),
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.card-branch {
  width: 500px;;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  @media (min-width: 600px) {
    flex-direction: row;
  }
  h5 {
    font-size: 16px;
    font-weight: bold;
    color: $dark_blue_3;
  }
  h6 {
    font-size: 12px;
    color: $main_2;
    font-weight: normal;
  }
  &__image {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-width: 100px;
  }
  .desc {
    font-size: 12px;
    color: $main_2;
  }
  .queue {
    font-size: 12px;
    width: 100%;
  }
  .count {
    font-size: 30px;
    line-height: 30px;
    font-weight: bold;
  }
  .direction {
    width: 100%;
    font-size: 12px;
    color: $dark_blue_3;
    font-weight: bold;
  }
  .button {
    font-size: 12px;
  }
  a {
    text-decoration: none;
  }
}
</style>
